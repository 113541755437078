<template>
    <div class="protection_works">
        <taskCommon
            title="客流高峰时段现场照片"
            :currentTree="currentTree"
            :list="list"
            :snSearch="snSearch"
            :listConfig="listConfig"
            :defaultProps="defaultProps"
            widthValue="280px"
            @handleChange="handleChange"
        >
            <slot>
                <Mymap
                    :mapData="mapData"
                    class="map"
                    ref="myMap"
                    @initmap="initMap"
                ></Mymap>
                <Dialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    :MC="MC"
                    @closeDisesaseDialog="closeDisesaseDialog"
                    @saveSuccess="saveSuccess"
                >
                </Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from "../task-common";
import mixins from "../mixins";
import Dialog from "./commuter_rush_hour_dialog";
import { mapActions } from "vuex";
export default {
    name: "archaeology_dig",
    components: {
        taskCommon,
        Dialog,
    },
    mixins: [mixins],
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            currentId: 1105,
            list: [],
            listConfig: {
                name: "MC",
                value: "ID",
            },
            basicMap: null,
            MC: "",
            selectedId: "",
            selectedIndex: 0,
            areaLayers: null,
            defaultProps: {
                children: "child",
                label: "MC",
            },
        };
    },
    mounted() {
        this.dialogData.title = "客流高峰时段现场照片监测配置";
    },
    methods: {
        ...mapActions(["getCommuterRushHourList"]),
        handleClose() {},
        // 获得客流高峰时段现场照片项目列表
        async getCommuterList() {
            let res = await this.getCommuterRushHourList({
                ItemCode: this.currentId,
            });
            this.list = res;
            this.initMarker();
        },
        initMarker() {
            // 渲染景点
            let _this = this;
            let markers = [];
            let map = this.basicMap;
            // eslint-disable-next-line
            let myIcon1 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_default.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line
            let myIcon2 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_choose.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line
            let myIcon3 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_otherp.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            this.list.forEach((item) => {
                if (item.JD && item.WD) {
                    // eslint-disable-next-line
                    let marker = L.marker([item.WD, item.JD], {
                        icon: item.JCPZCOUNT ? myIcon3 : myIcon1,
                        attribution: item,
                    });
                    marker
                        .on("mouseover", (e) => {
                            // eslint-disable-next-line
                            L.popup({
                                closeOnClick: false,
                                closeButton: false,
                                offset: [0, 5],
                                minWidth: 12,
                            })
                                .setLatLng([item.WD, item.JD])
                                .setContent(item.MC)
                                .openOn(map);
                            marker.setIcon(myIcon2);
                        })
                        .on("mouseout", (e) => {
                            map.closePopup();
                            marker.setIcon(item.JCPZCOUNT ? myIcon3 : myIcon1);
                        })
                        .on("click", (e) => {
                            this.currentTree = item.ID;
                            this.$refs.dialog.getDetail([this.currentTree]);
                            this.dialogData.title = item.MC + "-监测任务配置";
                            this.dialogData.dialog = true;
                        });
                    if (_this.currentTree === item.ID) {
                        this.$refs.myMap.changeFitBounds(item.WD, item.JD);
                    }
                    markers.push(marker);
                    if (item.JCPZCOUNT) {
                        // eslint-disable-next-line
                        let divIcon = L.divIcon({
                            className: "my-div-icon",
                            html:
                                '<div style="width:16px;height:16px;background:red;color:#fff;border-radius:50%;line-height: 16px;text-align:center;">' +
                                item.JCPZCOUNT +
                                "</div>",
                            iconAnchor: [-3, 25],
                        });
                        // eslint-disable-next-line
                        let numMarker = L.marker([item.WD, item.JD], {
                            icon: divIcon,
                            attribution: item,
                        });
                        markers.push(numMarker);
                    }
                    if (
                        this.$route &&
                        this.$route.params &&
                        this.$route.params.data &&
                        this.$route.params.data.jcdxid &&
                        this.$route.params.data.jcdxid === item.ID
                    ) {
                        this.basicMap.setView([item.WD, item.JD], 17);
                        L.popup({
                            closeOnClick: false,
                            closeButton: false,
                            offset: [0, 5],
                            minWidth: 12,
                        })
                            .setLatLng([item.WD, item.JD])
                            .setContent(item.MC)
                            .openOn(map);
                    }
                }
            });
            // eslint-disable-next-line
            this.featureGroup = L.featureGroup(markers).addTo(map);
            this.basicMap = map;
        },
        async changeList(val) {
            // 点击景点列表，直接显示弹出框
            this.currentTree = val[0];
            this.MC = val[1];
            this.selectedIndex = val[2];
            this.getJcDetail(val);
        },
        // 从列表视图点击 获得配置详情
        getJcDetail(item) {
            this.selectedId = item[0];
            this.dialogData.title = `${this.MC}-监测任务配置`;
            this.dialogData.dialog = true;
            this.$refs.dialog.getDetail(item);
        },
        // 关闭弹框时 清除选中
        closeDisesaseDialog() {
            this.selectedId = "";
        },
        async initMap(map) {
            this.basicMap = map;
            await this.getCommuterList();
        },
        // 保存成功后 重新获得列表
        async saveSuccess() {
            await this.getCommuterList();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>